<script setup>
import { useDisplay } from 'vuetify'

// mobile breakpoint
const { xs } = useDisplay()

// error object expected as prop
defineProps({
    title: {
        type: String,
        default: 'Oops!',
    },
    subtitle: {
        type: String,
        default: '',
    },
    icon: {
        type: String,
        default: 'mdi-alert-circle',
    },
    iconSize: {
        type: Number,
        default: 124,
    },
    message: {
        type: String,
        default: 'Looks like something went wrong.',
    },
    showLinks: {
        type: Boolean,
        default: false,
    },
    details: {
        type: String,
        default: 'Please try again later.',
    },
})
</script>

<template>
    <v-container id="sterror" fluid class="fill-height justify-center align-start">
        <v-row class="slide-from-top">
            <v-col>
                <div variant="flat" class="d-flex flex-column align-center justify-center">
                    <v-icon color="error" :size="xs ? 96 : iconSize">
                        {{ icon }}
                    </v-icon>
                    <v-card-title class="pa-8 text-center text-h2 text-sm-h1 text-error">
                        {{ title }}
                    </v-card-title>
                    <v-card-subtitle class="pa-2 text-center text-h5 text-error">
                        {{ subtitle }}
                    </v-card-subtitle>
                    <v-card-text class="mt-4 text-center">
                        <v-row>
                            <v-col cols="12" class="text-h6">{{ message }}</v-col>
                            <v-col class="text-h6">{{ details }}</v-col>
                        </v-row>
                    </v-card-text>
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>

<style scoped></style>
